<script setup lang="ts">
import Button from './Button.vue'
import { useGlobalConfirmDialog } from '@/composables/useConfirmDialog'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { isRevealed, confirmType, confirmTitle, confirmDescription, confirm, cancel } = useGlobalConfirmDialog()
</script>

<template>
	<TransitionRoot as="template" :show="isRevealed">
		<Dialog class="global-confirm-dialog relative z-50" @close="cancel()">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
							<div class="sm:flex sm:items-start">
								<!--Icon-->
								<div v-if="confirmType === 'danger'" class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
									<ExclamationTriangleIcon class="h-6 w-6 text-red-main" aria-hidden="true" />
								</div>
								<div v-if="confirmType === 'primary'" class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
									<ExclamationCircleIcon class="h-6 w-6 text-primary-main" aria-hidden="true" />
								</div>

								<div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
									<DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
										{{ confirmTitle }}
									</DialogTitle>
									<div class="mt-2">
										<p class="text-sm text-gray-500">{{ confirmDescription }}</p>
									</div>
								</div>
							</div>
							<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
								<Button class="ml-3" :variant="confirmType" @click.stop="confirm()">{{ t('common.confirm') }}</Button>
								<Button variant="cancel" @click.stop="cancel()">{{ t('common.cancel') }}</Button>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
