import { createPinia } from 'pinia'
import { createApp } from 'vue'

import Vue3ColorPicker from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'
import './index.css'

import i18n from '@/i18n'
import App from './App.vue'
import router from './router'

import * as sentry from '@sentry/vue'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(Vue3ColorPicker)

if (import.meta.env.VITE_ENV === 'production') {
	sentry.init({
		app,
		environment: import.meta.env.VITE_ENV,
		dsn: 'https://47258f555d08d58732d85a448a852f0e@o4507368001503232.ingest.de.sentry.io/4507368003928144',
		integrations: [sentry.browserTracingIntegration(), sentry.replayIntegration()],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [/^https:\/\/saleswave\.ai\//],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

app.mount('#app')
