/**
Usage example:

import { useNotification } from '@/composables/useNotification'
const { addNotification } = useNotification()

function showSuccessNotification() {
	addNotification({ type: 'success', title: 'Success', message: 'Data saved successfully.' })
}

function showErrorNotification() {
	addNotification({ type: 'error', title: 'Error', message: 'Failed to save data.' })
}

 */

import { ref } from 'vue'
import * as Sentry from '@sentry/vue' // Import Sentry

import type { Notification, ErrorNotification } from '@/types/notification'

const notifications = ref<Notification[]>([])

export function useNotification() {
	const showNotification = (notification: Notification) => {
		const id = Date.now()
		notifications.value.unshift({
			id: id,
			type: notification.type,
			title: notification.title,
			message: notification.message
		})
		setTimeout(() => removeNotification(id), 5000)
	}

	const showErrorNotification = (errorNotification: ErrorNotification) => {
		const id = Date.now()
		notifications.value.unshift({
			id: id,
			type: 'error',
			title: errorNotification.title,
			message: errorNotification.message
		})

		// Send the error to Sentry using the Error object from ErrorNotification
		if (errorNotification.error instanceof Error) {
			console.log('Sending error to Sentry:', errorNotification.error)
			Sentry.captureException(errorNotification.error, {
				tags: {
					description: errorNotification.message,
					message: errorNotification.sentryMessage
				}
			})
		}

		setTimeout(() => removeNotification(id), 7500)
	}

	const removeNotification = (id: number) => {
		notifications.value = notifications.value.filter((notification) => notification.id !== id)
	}

	return {
		notifications,
		showNotification,
		removeNotification,
		showErrorNotification
	}
}
