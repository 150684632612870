<script setup lang="ts">
import { useNotification } from '@/composables/useNotification'
import Notification from './Notification.vue'

const { notifications } = useNotification()
</script>

<template>
	<div aria-live="assertive" class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
		<div class="flex w-full flex-col items-center space-y-4 sm:items-end">
			<Notification v-for="notification in notifications" :key="notification.id" :type="notification.type" :title="notification.title" :message="notification.message" />
		</div>
	</div>
</template>
