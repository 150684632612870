/**
Usage Example:

import { useGlobalConfirmDialog } from '@/composables/useConfirmDialog'
const { showConfirmDialog } = useGlobalConfirmDialog()

async function onDelete() {
	const confirmed = await showConfirmDialog({ type: 'danger', title: 'Delete Item!', description: 'Are you sure you want to delete this item?' })
	if (confirmed) {
		// User confirmed
		console.log('Item deleted')
	} else {
		// User canceled
		console.log('Deletion canceled')
	}
}
*/

import { ref } from 'vue'
import { useConfirmDialog } from '@vueuse/core'
import type { ConfirmDialogInput } from '@/types/confirm-dialog'

import type { Ref } from 'vue'

const isRevealed: Ref<boolean> = ref(false)
const confirmType: Ref<string> = ref('')
const confirmTitle: Ref<string> = ref('')
const confirmDescription: Ref<string> = ref('')

const { reveal, confirm, cancel } = useConfirmDialog(isRevealed)

export function useGlobalConfirmDialog() {
	async function showConfirmDialog(input: ConfirmDialogInput): Promise<Boolean> {
		confirmType.value = input.type
		confirmTitle.value = input.title
		confirmDescription.value = input.description

		const { isCanceled } = await reveal()
		return !isCanceled
	}

	return {
		isRevealed,
		confirmType,
		confirmTitle,
		confirmDescription,
		showConfirmDialog,
		confirm,
		cancel
	}
}
